<template>
  <div class="home">
    <div id="full-screen-slider">
      <ul id="slides">
        <li v-for="item in banners" :key="item.id"
          :style="
            'background: url(&quot;' +
              item.picUrl +
              '&quot;) center top no-repeat;'
          ">
          <a></a>
        </li>
      </ul>
    </div>
    <div class="about">
      <div class="about_l">
        <a href="/about?cid=7501&id=23474" class="about_t">中心概况</a>
        <div class="jj_box">
          <span>CENTER PROFILE</span>
          <a href="/about?cid=7501&id=23474">中心简介</a>
        </div>
      </div>
      <div class="about_txt" v-html="aboutContent"></div>
    </div>
    <div class="case_wrap_box">
      <div class="case_wrap">
        <div class="title">
          <h4>资质荣誉</h4>          
        </div>
        <div class="case_wrap_images">
          <img v-for="item in certificates" :key="item.id" :src="item.picUrl" />
        </div>
      </div>
    </div>
    <img class="ban-ad" :src="indexAd" />
  </div>
</template>

<script>
const WEBAPI = require("apifm-webapi");
WEBAPI.init("gavin");
export default {
  name: "Home",
  data() {
    return {
      banners: [],
      certificates: [],
      aboutContent: undefined,
      indexAd: undefined
    };
  },
  mounted() {
    this.$store.commit("setNavPos", 'index')
    this.fetchConfigs();
    this.fetchBanners();
    this.fetchCertificates();
    this.fetchAbout();
  },
  methods: {
    async fetchBanners() {
      const res = await WEBAPI.banners({ type: "index" });
      if (res.code == 0) {
        this.banners = res.data;
      }
    },
    async fetchCertificates() {
      const res = await WEBAPI.banners({ type: "zz,ry" });
      if (res.code == 0) {
        this.certificates = res.data;
      }
    },
    async fetchAbout() {
      const res = await WEBAPI.cmsPage("about");
      if (res.code == 0) {
        this.aboutContent = res.data.content;
      }
    },
    async fetchConfigs(){
      const res = await WEBAPI.queryConfigBatch('index_ad')
      if (res.code == 0) {
        res.data.forEach(ele => {
          if (ele.key == 'index_ad') {
            this.indexAd = ele.value
          }
        })
      }
    }
  }
};
</script>
<style>
#full-screen-slider {
  width: 100%;
  height: 400px;
  position: relative;
  clear: both;
}
#slides {
  display: block;
  width: 100%;
  height: 400px;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
#slides li {
  display: block;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
}
#slides li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
}
#pagination {
  display: block;
  list-style: none;
  position: absolute;
  left: 40%;
  bottom: 20px;
  z-index: 9900;
  padding: 5px 15px 5px 0;
  margin: 0;
}
#pagination li {
  display: block;
  list-style: none;
  width: 36px;
  height: 6px;
  float: left;
  margin-left: 15px;
  background: #fff;
}
#pagination li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-indent: -9999px;
}
#pagination li.current {
  background: #ff9900;
}

/*about*/
.about {
  width: 1100px;
  margin: 20px auto;
  overflow: hidden;
}
.about_l {
  width: 210px;
  float: left;
}
.about_t {
  width: 80px;
  height: 30px;
  display: block;
  background: #003e9c;
  color: #fff;
  line-height: 30px;
  text-align: center;
  margin-top: 75px;
  float: left;
}
.jj_box {
  width: 118px;
  height: 158px;
  border: 1px solid #003e9c;
  padding: 10px;
  float: left;
  margin-left: -10px;
  text-align: center;
}
.jj_box span {
  text-align: center;
  margin-top: 20px;
  width: 120px;
  height: 24px;
  display: block;
  line-height: 24px;
}
.jj_box a {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 24px;
  color: #333;
  padding-bottom: 40px;
  border-bottom: 1px solid #ff9c00;
}
.jj_box a:hover {
  color: #ff9c00;
}
.about_txt {
  overflow: hidden;
}
.about_txt p {
  padding-left: 32px;
  font-size: 16px;
  line-height: 40px;
  color: #333;
}
.about_r {
  width: 380px;
  float: right;
  height: 180px;
  overflow: hidden;
}
.about_r h3 {
  height: 30px;
  line-height: 30px;
  border-bottom: #ddd 1px solid;
  clear: both;
}
.about_r h3 a {
  color: #333;
}
.about_r h3 a:hover {
  color: #ff9c00;
}
.about_r li {
  width: 100%;
  height: 30px;
  line-height: 30px;
  float: left;
}
.about_r li a {
  float: left;
  color: #333;
}
.about_r li a:hover {
  color: #ff9c00;
}
.about_r li span {
  float: right;
  font-size: 12px;
}
/**/
.case_wrap_box {
  background: #f8f8f8;
  overflow: hidden;
}
.case_wrap {
  width: 1100px;
  padding: 20px 0px;
  margin: 0 auto;
  clear: both;
}
.case_wrap .title h4 {
  text-align: center;
  height: 40px;
  line-height: 40px;
  clear: both;
  font-size: 20px;
  color: #003e9c;
}
.case_wrap .title h4 span {
  color: #666;
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
}
.case_wrap .title {
  text-align: center;
  line-height: 30px;
}
.case_wrap_images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}
.case_wrap_images img {
  width: 240px;
  margin-left: 32px;
}
.ban-ad {
  width: 100%;
  height: auto;;
}
</style>
