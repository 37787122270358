import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navPos: 'index'
  },
  mutations: {
    setNavPos (state, navPos) {
      state.navPos = navPos
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    navPos: state => state.navPos,
  }
})
