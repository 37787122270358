<template>
  <div class="footer_box">
    <div class="footer">
      <div class="footer_r">
        <div class="dz">
          <p>
            公司负责人：王红梅&nbsp;&nbsp;&nbsp;联系电话：13056298213、15062120291<br />
            公司地址：徐州市泉山区锦江豪庭A座301室<br />
            传    真：0516-83812460<br />
            邮    箱：937500483@qq.com 、 2575104957 @qq.com
          </p>
        </div>
        <p class="qqq">
          Copyright 2020 © 版权所有：徐州格瑞环保咨询有限公司<br />
          <a href="http://www.beian.miit.gov.cn/" target="_blank" style="color:#fff;">ICP备：苏ICP备19073365号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foot",
  data() {
    return {};
  }
};
</script>

<style scoped>
/*footer_box*/
.footer_box {
  background: #333;
}
.footer {
  width: 1100px;
  margin: 0 auto;
  padding: 30px 0;
  overflow: hidden;
}
.footer_l {
  width: 220px;
  float: left;
}
.ewm_sj {
  width: 90px;
  float: left;
  margin-right: 40px;
  text-align: center;
}
.ewm_wx {
  margin-right: 0;
}
.ewm_sj img {
  width: 90px;
  height: 90px;
}
.ewm_sj p {
  height: 30px;
  line-height: 30px;
  color: #fff;
}
.footer_r {
  width: 840px;
  float: right;
}
.dz {
  width: 540px;
  float: left;
}
.dz p {
  color: #fff;
  line-height: 30px;
}
.bqq {
  width: 200px;
  float: right;
}
.bqq a {
  margin-left: 30px;
}
.qqq {
  clear: both;
  color: #fff;
  line-height: 30px;
}
</style>
