<template>
  <div class="header_box">
    <div class="header">
      <div class="logo">
        <a href="\"> <img :src="logo"/></a>
      </div>
      <div class="tel">
        <img :src="telPic" />
      </div>
    </div>
  </div>
</template>

<script>
const WEBAPI = require('apifm-webapi')
WEBAPI.init('gavin')

export default {
  name: "Header",
  data() {
    return {
      logo: undefined,
      telPic: undefined,
    }
  },
  mounted() {
    this.fetchConfigs()
  },
  methods: {
    async fetchConfigs(){
      const res = await WEBAPI.queryConfigBatch('logo,telPic')
      if (res.code == 0) {
        res.data.forEach(ele => {
          if (ele.key == 'logo') {
            this.logo = ele.value
          }
          if (ele.key == 'telPic') {
            this.telPic = ele.value
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.header_box{border-bottom:1px solid #ddd;}
.header{ width:1100px; margin:0 auto; height:100px;}
.logo{ float:left;}
.logo img{ height:100px;}
.tel{float:right;}
.tel img{height:100px;}
</style>
