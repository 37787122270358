<template>
  <div class="menu">
    <ul>
      <li class="mainlevel" id="mainlevel_01">
        <a :class="navPos == 'index' ? 'active': ''" href="/">网站首页</a>
      </li>
      <li class="mainlevel" id="mainlevel_02"><a :class="navPos == 'about' ? 'active': ''" href="/about?cid=7501&id=23476">中心简介</a>
        <ul id="sub_01" style="display: none;">
          <li><a href="/about?cid=7501&id=23476">组织机构</a></li>
          <li><a href="/about?cid=7501&id=23477">中心文化</a></li>
          <li><a href="/about?cid=7501&id=23478">分支机构</a></li>
        </ul>
      </li>
      <li class="mainlevel" id="mainlevel_03"><a :class="navPos == 'service' ? 'active': ''" href="/service?cid=8068&id=24353">服务领域</a>
        <ul id="sub_01" style="display: none;">
          <li><a href="/service?cid=8068&id=24353">环境影响评价</a></li>
          <li><a href="/service?cid=8068&id=24354">水土保持项目咨询</a></li>
          <li><a href="/service?cid=8068&id=24355">防洪评价</a></li>
          <li><a href="/service?cid=8068&id=24356">环境监测</a></li>
          <li><a href="/service?cid=8068&id=24357">环境污染治理</a></li>
          <li><a href="/service?cid=8068&id=24358">污染治理设施代营业服务</a></li>
          <li><a href="/service?cid=8068&id=24359">环境工程监理及营业管理咨询</a></li>
        </ul>
      </li>
      <li class="mainlevel" id="mainlevel_04"><a :class="navPos == 'dongtai' ? 'active': ''" href="/news?cid=8069&id=8070">咨询动态</a>
        <ul id="sub_01" style="display: none;">
          <li><a href="/news?cid=8069&id=8070">行业动态</a></li>
          <li><a href="/news?cid=8069&id=8071">通知公告</a></li>
        </ul>
      </li>
      <li class="mainlevel" id="mainlevel_05"><a :class="navPos == 'honor' ? 'active': ''" href="/honor?type=zz">资质荣誉</a>
        <ul id="sub_01" style="display: none;">
          <li><a href="/honor?type=zz">资质证书</a></li>
          <li><a href="/honor?type=ry">荣誉证书</a></li>
        </ul>
      </li>
      <li class="mainlevel" id="mainlevel_06"><a :class="navPos == 'concat' ? 'active': ''" href="/concat?cid=7503&id=23481">联系我们</a>
        <ul id="sub_01" style="display: none;">
          <li><a href="/concat?cid=7503&id=23481">联系方式</a></li>
          <li><a href="/concat?cid=7503&id=23482">加盟合作</a></li>
          <li><a href="/concat?cid=7503&id=24372">投资建议</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const WEBAPI = require("apifm-webapi");
WEBAPI.init("gavin");

export default {
  name: "Menu",
  data() {
    return {
      logo: undefined,
      telPic: undefined
    };
  },
  computed: {
    ...mapGetters([
      'navPos'
    ])
  },
  mounted() {
    this.fetchConfigs();
  },
  methods: {
    async fetchConfigs() {
      const res = await WEBAPI.queryConfigBatch("logo,telPic");
      if (res.code == 0) {
        res.data.forEach(ele => {
          if (ele.key == "logo") {
            this.logo = ele.value;
          }
          if (ele.key == "telPic") {
            this.telPic = ele.value;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.menu{background:#fff; height:40px;position:relative; z-index:9999;clear: both;}
.menu ul{width:1098px; margin:0 auto;}
.mainlevel{width:180px; /*IE6 only*/  text-align:center; position:relative; z-index:99; float:left; border-right:1px solid #ddd;}
.mainlevel a{width:100%; height:40px; line-height:40px; display:block; color:#333; font-size:15px;}
.mainlevel a:hover,.mainlevel a.active{ background:#003e9c; color:#fff;}
.mainlevel ul{width:180px; display:none; position:absolute; left:0px; padding:10px 5px; background:#095ee0; z-index:999; top:40px;}
.mainlevel li{width:100%;/*IE6 only*/ height:100px; line-height:100px; text-align:center;}
.mainlevel ul li{height:30px; line-height:30px;}
.mainlevel li a{width:100%;/*IE6 only*/ display:block; height:30px; line-height:30px; background:none; color:#fff; font-size:12px;}
.mainlevel li a:hover{background:#003e9c; font-weight:normal;}
</style>
