<template>
  <div id="app">
    <Header />
    <Menu />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
    <Foot />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Menu from "@/components/Menu.vue";
import Foot from "@/components/Foot.vue";

export default {
  name: "Home",
  components: {
    Header,
    Menu,
    Foot
  }
};
</script>
<style>
/* CSS Document */
*{margin:0; padding:0;}
body{color:#666; font-size:14px; font-family:"微软雅黑",Arial, Helvetica, sans-serif;}
ul li{list-style:none;}
a{text-decoration:none;}
a:hover{transition-property:color; transition-duration:.6s}
img{border:none;}
.clr{clear:both;}
input{outline:medium;}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
