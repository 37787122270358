import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/honor',
    name: 'Honor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Honor.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetail.vue')
  },
  {
    path: '/concat',
    name: 'Concat',
    component: () => import(/* webpackChunkName: "about" */ '../views/Concat.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // hash history
  base: process.env.BASE_URL,
  routes
})

export default router
